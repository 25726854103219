import { render, staticRenderFns } from "./Fatca.vue?vue&type=template&id=2cd3a250&scoped=true&"
import script from "./Fatca.vue?vue&type=script&lang=js&"
export * from "./Fatca.vue?vue&type=script&lang=js&"
import style0 from "./Fatca.vue?vue&type=style&index=0&id=2cd3a250&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cd3a250",
  null
  
)

export default component.exports