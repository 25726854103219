<template>
    <div class="form">
        <h1>Анкета физического лица (в целях FATCA) <rwm-question-mark footnote-number="1" text="FATCA (Foreign Account Tax Compliance Act) – Закон США о налогообложении иностранных счетов" /> </h1>
        <div class="form__body">
            <client-block :mname="user.userName.mname" :name="user.userName.name" :sname="user.userName.sname"/>
            <passport-block :department="user.passport.department" :issue="user.passport.issue"
                            :issue-date="user.passport.issueDate"
                            :number="user.passport.number"/>
            <group-selecting
                    class="block"
                    name="citizenship"
                    question="Являетесь ли Вы гражданином США (в том числе при наличии двух и более гражданств)?"
                    :checked-false="!citizenship"
                    :checked-true="citizenship"
                    label-true="Да"
                    label-false="Нет"
                    label-position="bottom"
                    :value-true="true"
                    :value-false="false"
                    v-model="_citizenship"
                    :disabled="disabled"
            />
            <group-selecting
                    class="block"
                    name="residence"
                    question="Имеете ли Вы разрешение на постоянное пребывание (вид на жительство) в США (являетесь владельцем Green Card)?"
                    :checked-false="!residence"
                    :checked-true="residence"
                    label-true="Да"
                    label-false="Нет"
                    label-position="bottom"
                    :value-true="true"
                    :value-false="false"
                    v-model="_residence"
                    :disabled="disabled"
            />
            <group-selecting
                    class="block"
                    name="UsTaxResident"
                    question="Соответствуете ли Вы критериям «долгосрочного пребывания в США» для признания налогоплательщиком – налоговым резидентом США? Физическое лицо признается налоговым резидентом США по основанию «долгосрочного пребывания», если оно находилось на территории США не менее 31 дня в течение текущего календарного года и не менее 183 дней в течение 3 лет, включая текущий год и два непосредственно предшествующих года. При этом сумма дней, в течение которых физическое лицо присутствовало на территории США в текущем году, а также двух предшествующих годах, умножается на установленный коэффициент: коэффициент для текущего года равен 1, коэффициент предшествующего года равен 1/3, коэффициент позапрошлого года равен 1/6. Налоговыми резидентами США не признаются учителя, студенты и стажеры, временно присутствовавшие на территории США на основании виз типа «F», «J», «M» или «Q»."
                    :checked-false="!_UsTaxResident"
                    :checked-true="_UsTaxResident"
                    label-true="Да"
                    label-false="Нет"
                    label-position="bottom"
                    :value-true="true"
                    :value-false="false"
                    v-model="_UsTaxResident"
                    :disabled="disabled"
            />
            <group-selecting
                    class="block"
                    name="bornState"
                    question="Является ли местом Вашего рождения территория США?"
                    :checked-false="bornState !== null ? !bornState : false"
                    :checked-true="bornState !== null ? bornState : false"
                    label-true="Да"
                    label-false="Нет"
                    label-position="bottom"
                    :value-true="true"
                    :value-false="false"
                    v-model="_bornState"
                    :disabled="disabled"
            />
            <transition name="slide" mode="out-in">
                <div v-if="bornState" class="additional-data">
                    <div class="block">
                        <group-selecting
                                name="renouncedCitizenship"
                                question="Вы отказались от гражданства США. (В данном случае необходимо предоставить Свидетельство об утрате гражданства США/CERTIFICATE OF LOSS OF NATIONALITY OF THE UNITED STATES (форма DS 4083));"
                                :checked-false="_renouncedCitizenship !== null ? !_renouncedCitizenship : _renouncedCitizenship"
                                :checked-true="_renouncedCitizenship"
                                label-true="Да"
                                label-false="Нет"
                                label-position="bottom"
                                :value-true="true"
                                :value-false="false"
                                v-model="_renouncedCitizenship"
                                ref-false="renouncedCitizenshipFalse"
                                :set-click="renouncedCitizenshipRef"
                                :error="errors.renouncedCitizenship"
                        />
                        <transition name="slide" mode="out-in">
                            <div v-if="_renouncedCitizenship" class="row">
                                <div class="col-3">
                                    <div class="load-file">
                                        <rwm-input-file
                                                title="Прикрепите форму DS 4083"
                                                :filename="_renouncedCitizenshipFile ? _renouncedCitizenshipFile.origin_name : ''"
                                                refer="renouncedCitizenshipFile"
                                                @FileChange="onFileLoad($event, 'renouncedCitizenshipFile')"
                                                @deleteFile="deleteItem('renouncedCitizenshipFile')"
                                                :error="errors.renouncedCitizenshipFile"
                                        />
                                    </div>
                                </div>
                                <div class="col">
                                    <p>Пожалуйста, прикрепите Свидетельство об утрате гражданства США/CERTIFICATE OF LOSS OF NATIONALITY OF THE UNITED STATES (форма DS 4083))</p>
                                </div>
                            </div>
                        </transition>
                    </div>
                    <div class="block">
                        <group-selecting
                                name="otherReasonsOfRenounced"
                                question="Имеются иные основания отсутствия гражданства США. (В данном случае необходимо дополнительно предоставить  заявление с указанием причин отсутствия гражданства США;"
                                :checked-false="_otherReasonsOfRenounced !== null ? !_otherReasonsOfRenounced : _otherReasonsOfRenounced"
                                :checked-true="_otherReasonsOfRenounced"
                                label-true="Да"
                                label-false="Нет"
                                label-position="bottom"
                                :value-true="true"
                                :value-false="false"
                                v-model="_otherReasonsOfRenounced"
                                ref-false="otherReasonsOfRenouncedFalse"
                                :set-click="otherReasonsOfRenouncedRef"
                                :error="errors.otherReasonsOfRenounced"
                        />
                        <transition name="slide" mode="out-in">
                            <div v-if="otherReasonsOfRenounced" class="row">
                                <div class="col-3">
                                    <div class="load-file">
                                        <rwm-input-file
                                                title="Прикрепите заявление"
                                                :filename="_otherReasonsOfRenouncedFile ? _otherReasonsOfRenouncedFile.origin_name : ''"
                                                refer="renouncedCitizenshipFile"
                                                @FileChange="onFileLoad($event, 'otherReasonsOfRenouncedFile')"
                                                @deleteFile="deleteItem('otherReasonsOfRenouncedFile')"
                                                :error="errors.otherReasonsOfRenouncedFile"
                                        />
                                    </div>
                                </div>
                                <div class="col">
                                    <p>Пожалуйста, прикрепите заявление с указанием причин отсутствия гражданства США</p>
                                </div>
                            </div>
                        </transition>
                    </div>
                    <group-selecting
                            class="block"
                            name="notRenounced"
                            question="Вы не отказывались от гражданства США и у Вас нет иных оснований отсутствия гражданства США."
                            :checked-false="_notRenounced !== null ? !_notRenounced : _notRenounced"
                            :checked-true="_notRenounced"
                            label-true="Да"
                            label-false="Нет"
                            label-position="bottom"
                            :value-true="true"
                            :value-false="false"
                            v-model="_notRenounced"
                            ref-true="notRenouncedTrue"
                            ref-false="notRenouncedFalse"
                            :set-click="notRenouncedRef"
                            :error="errors.notRenounced"
                    />
                    <group-selecting
                            class="block"
                            name="hasUsAddress"
                            question="Имеете ли Вы адрес проживания и (или) почтовый адрес на территории США?"
                            :checked-false="_hasUsAddress !== null ? !_hasUsAddress : _hasUsAddress"
                            :checked-true="_hasUsAddress"
                            label-true="Да"
                            label-false="Нет"
                            label-position="bottom"
                            :value-true="true"
                            :value-false="false"
                            v-model="_hasUsAddress"
                            :error="errors.hasUsAddress"
                    />
                    <group-selecting
                            class="block"
                            name="hasUsPhone"
                            question="Имеете ли Вы номер контактного телефона и (или) факса на территории США?"
                            :checked-false="hasUsPhone !== null ? !hasUsPhone : hasUsPhone"
                            :checked-true="hasUsPhone"
                            label-true="Да"
                            label-false="Нет"
                            label-position="bottom"
                            :value-true="true"
                            :value-false="false"
                            v-model="_hasUsPhone"
                            :error="errors.hasUsPhone"
                    />
                    <group-selecting
                            name="taxResident"
                            question="Являетесь ли Вы налоговым резидентом США? Если ответ «Да», то необходимо ниже указать SSN/ITIN, а также фамилию, имя и, если имеется, отчество на английском языке в соответствии с документами, удостоверяющими личность, выданными официальными органами США."
                            :checked-false="taxResident !== null ? !taxResident : taxResident"
                            :checked-true="taxResident"
                            label-true="Да"
                            label-false="Нет"
                            label-position="bottom"
                            :value-true="true"
                            :value-false="false"
                            v-model="_taxResident"
                            :error="errors.taxResident"
                    />
                    <transition name="slide" mode="out-in">
                        <div v-if="taxResident" class="tax-resident">
                            <div class="row" style="align-items: center">
                                <div class="col">
                                    <rwm-text-field
                                            width="100%"
                                            label="Фамилия, имя, отчество на английском языке"
                                            v-model="_engName"
                                            @blur="validateEngName"
                                            :error="errors.engName"
                                    />
                                </div>
                                <div class="ssnitin-group" style="align-items: center">
                                    <div class="group-selecting" style="align-items: center">
                                        <group-selecting
                                                name="ssnitin"
                                                :question="null"
                                                :checked-false="ssnitin !== null ? !!ssnitin && ssnitin==='ITIN' : false"
                                                :checked-true="ssnitin !== null ? !!ssnitin && ssnitin==='SSN' : false"
                                                :mark-one="{footnoteNumber: 2, text: 'Пожалуйста, укажите SSN/ITIN , а также фамилию, имя и, если имеется, отчество на английском языке в соответствии с документами, удостоверяющими личность, выданными официальными органами США'}"
                                                label-true="SSN"
                                                label-false="ITIN"
                                                label-position="bottom"
                                                value-true="SSN"
                                                value-false="ITIN"
                                                v-model="_ssnitin"
                                                style="align-items: center"
                                        />
                                    </div>
                                    <div class="col" style="min-width: 200px">
                                        <rwm-text-field
                                                :disabled="!_ssnitin"
                                                v-mask="'###-##-####'"
                                                :label="_ssnitin === 'SSN' ? 'Номер социального страхования' : _ssnitin === 'ITIN' ? 'Идентификационный номер налогоплательщика' : ''"
                                                width="100%"
                                                v-model="_ssnNumber"
                                                :error="errors.ssnNumber"
                                                @blur="validateSsnNumber"
                                        />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <p>Пожалуйста, укажите SSN/ITIN , а также фамилию, имя и, если имеется, отчество на английском языке в соответствии с документами, удостоверяющими личность, выданными официальными органами США</p>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </transition>
            <div class="row">
                <div class="col-12 block">
                    <h3>Подтверждение:</h3>
                    <p class="anketa">Я подтверждаю достоверность сведений, указанных в настоящей анкете.</p>
                    <p class="anketa">Я обязуюсь предоставлять информацию об изменении указанных в настоящей анкете сведений (новую анкету) не позднее 30 (тридцати) дней с момента их изменения, а также дополнительную информацию в целях исполнения FATCA по запросу ЗАО УК «РВМ Капитал» (далее – Управляющая компания).</p>
                    <p class="anketa">Я понимаю, что несу ответственность за предоставление ложных и заведомо недостоверных сведений о себе в соответствии с применимым законодательством.</p>
                    <p class="anketa">Я понимаю, что в случае предоставления мной недостоверных или неполных сведений, с меня может быть взыскана компенсация за причиненные убытки.</p>
                    <p class="anketa">Я понимаю, что в случае непредоставления заполненной анкеты или согласия на передачу данных в налоговый орган США и (или) налоговому агенту, уполномоченному налоговым органом США, или предоставления недостоверных данных в анкете, Управляющая компания в соответствии с п.7 ст.2 и п.1, п.2 ст.5 Федерального закона от 28.06.2014 № 173-ФЗ «Об особенностях осуществления финансовых операций с иностранными гражданами» вправе принять решение об отказе в заключении договора или принять решение об отказе от совершения операции и (или) расторжении в одностороннем порядке заключенного договора (в случае наличия у Управляющей компании документально подтвержденного предположения, что я являюсь налогоплательщиком США).</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 block">
                    <h3>Согласие:</h3>
                    <p class="anketa">В случае если в соответствии с FATCA содержащаяся в настоящей анкете информация, а также иная известная Управляющей компании информация, подлежит передаче в налоговый орган США и (или) налоговому агенту, уполномоченному налоговым органом США:</p>
                    <p class="anketa">Я даю согласие на предоставление Управляющей компанией информации обо мне налоговому органу США, а также иностранным налоговым агентам, уполномоченным налоговым органом США, в том числе информации, необходимой для заполнения установленных FATCA форм отчетности;</p>
                    <p class="anketa">Я также даю Управляющей компании согласие на обработку моих персональных данных, в том числе трансграничную передачу персональных данных в налоговый орган США и (или) иностранным налоговым агентам, уполномоченным налоговым органом США, а также на предоставление налоговому органу США и (или) налоговому агенту, уполномоченному налоговым органом США, конфиденциальной информации обо мне, моем лицевом счете, включая операции по лицевому счету, а также информации, содержащейся в документах, представленных в Управляющую компанию, включая анкету клиента, поручения по лицевому счету, в случаях, предусмотренных применимым правом, Законом США о налогообложении иностранных счетов, без моего дополнительного согласия.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <rwm-button width="100%" @click="submit()" :disabled="!readyToSubmit || disabled">Подписать</rwm-button>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <rwm-sms-checking
                            v-model="_timer"
                            :time="60"
                            field-width="150"
                            @sendAgain="sendAgain()"
                            @verified="codeIsVerified"
                            :isShow="showChecking"
                    />
                </div>
            </div>
            <rwm-footnote :footnotes="footnote" />
        </div>
    </div>
</template>

<script>
    import ClientBlock from "./blocks/ClientBlock";
    import PassportBlock from "./blocks/PassportBlock";
    import GroupSelecting from "./blocks/GroupSelecting";
    import RwmTextField from "../../../../RWMTextField/RwmTextField";
    import RwmInputFile from "../../../../RWMInputFile/RwmInputFile";
    import RwmButton from "../../../../RWMButton/RwmButton";
    import RwmSmsChecking from "../../../../RWMSMSChecking/RwmSmsChecking";
    import RwmQuestionMark from "../../../../RWMQutestionMark/RwmQuestionMark";
    import RwmFootnote from "../../../../RWMFootnote/RwmFootnote";

    export default {
        name: "Fatca",
        components: {
            RwmFootnote,
            RwmQuestionMark,
            RwmSmsChecking, RwmButton, RwmInputFile, RwmTextField, GroupSelecting, PassportBlock, ClientBlock},
        props: {
            user: Object,
            timer: Boolean,
            showChecking: Boolean,
            disabled: Boolean,

            citizenship: Boolean,
            residence: Boolean,
            UsTaxResident: Boolean,
            bornState: Boolean,
            renouncedCitizenship: Boolean,
            otherReasonsOfRenounced: Boolean,
            notRenounced: Boolean,
            hasUsAddress: Boolean,
            hasUsPhone: Boolean,
            taxResident: Boolean,
            ssnitin: [String, null],
            ssnNumber: String,
            engName: String,
            renouncedCitizenshipFile: [File, Object, null],
            otherReasonsOfRenouncedFile: [File, Object, null],
        },
        watch: {
            engName(val) {
                this.temp_engName = val;
            },
            ssnNumber(val) {
                this.temp_ssnNumber = val;
            }
        },
        computed: {
            _timer: {
                get() {
                    return this.timer
                },
                set(val) {
                    this.$emit('timer', val)
                }
            },
            _citizenship: {
                get() {
                    return this.citizenship
                },
                set(val) {
                    this.readyToSubmit = true
                    this.$emit('update', {citizenship: val})
                }
            },
            _residence: {
                get() {
                    return this.residence
                },
                set(val) {
                    this.readyToSubmit = true
                    this.$emit('update', {residence: val})
                }
            },
            _UsTaxResident: {
                get() {
                    return this.UsTaxResident
                },
                set(val) {
                    this.readyToSubmit = true
                    this.$emit('update', {UsTaxResident: val})
                    this.errors.UsTaxResident = false;
                }
            },
            _bornState: {
                get() {
                    return this.bornState
                },
                set(val) {
                    this.readyToSubmit = true
                    this.$emit('update', {bornState: val})
                    this.errors.bornState = false;
                }
            },
            _renouncedCitizenship: {
                get() {
                    return this.renouncedCitizenship
                },
                set(val) {
                    this.readyToSubmit = true
                    this.$emit('update', {renouncedCitizenship: val})
                    this.errors.renouncedCitizenship = false;
                }
            },
            _otherReasonsOfRenounced: {
                get() {
                    return this.otherReasonsOfRenounced
                },
                set(val) {
                    this.readyToSubmit = true
                    this.$emit('update', {otherReasonsOfRenounced: val})
                    this.errors.otherReasonsOfRenounced = false;
                    if (val == true)
                        this.errors.notRenounced = false;
                }
            },
            _notRenounced: {
                get() {
                    return this.notRenounced
                },
                set(val) {
                    this.readyToSubmit = true
                    this.$emit('update', {notRenounced: val})
                    this.errors.notRenounced = false;
                }
            },
            _hasUsAddress: {
                get() {
                    return this.hasUsAddress
                },
                set(val) {
                    this.readyToSubmit = true
                    this.errors.hasUsAddress = false
                    this.$emit('update', {hasUsAddress: val})
                }
            },
            _hasUsPhone: {
                get() {
                    return this.hasUsPhone
                },
                set(val) {
                    this.readyToSubmit = true
                    this.errors.hasUsPhone = false
                    this.$emit('update', {hasUsPhone: val})
                }
            },
            _taxResident: {
                get() {
                    return this.taxResident
                },
                set(val) {
                    this.readyToSubmit = true
                    this.$emit('update', {taxResident: val})
                    this.errors.taxResident = false
                }
            },
            _ssnitin: {
                get() {
                    return this.ssnitin
                },
                set(val) {
                    this.readyToSubmit = true
                    this.$emit('update', {ssnitin: val})
                    this.errors.ssnitin = false;
                }
            },
            _ssnNumber: {
                get() {
                    return this.temp_ssnNumber
                },
                set(val) {
                    this.readyToSubmit = true
                    this.temp_ssnNumber = val
                    this.errors.ssnNumber = false;
                }
            },
            _engName: {
                get() {
                    return this.temp_engName;
                },
                set(val) {
                    this.readyToSubmit = true
                    this.temp_engName = val
                }
            },
            _renouncedCitizenshipFile: {
                get() {
                    return this.renouncedCitizenshipFile
                },
                set(val) {
                    this.readyToSubmit = true
                    this.$emit('update', {renouncedCitizenshipFile: val})
                    this.errors.renouncedCitizenshipFile = false;
                }
            },
            _otherReasonsOfRenouncedFile: {
                get() {
                    return this.otherReasonsOfRenouncedFile
                },
                set(val) {
                    this.readyToSubmit = true
                    this.$emit('update', {otherReasonsOfRenouncedFile: val})
                    this.errors.otherReasonsOfRenouncedFile = false;
                }
            },
        },
        data() {
            return {
                footnote: [
                    "FATCA (Foreign Account Tax Compliance Act) – Закон США о налогообложении иностранных счетов",
                    "Пожалуйста, укажите SSN/ITIN , а также фамилию, имя и, если имеется, отчество на английском языке в соответствии с документами, удостоверяющими личность, выданными официальными органами США",
                ],
                temp_ssnNumber: "",
                temp_engName: "",
                readyToSubmit: true,
                renouncedCitizenshipRef: "",
                otherReasonsOfRenouncedRef: "",
                notRenouncedRef: "",
                errors: {
                    engName: false,
                    ssnNumber: false,
                    renouncedCitizenshipFile: false,
                    otherReasonsOfRenouncedFile: false,
                    renouncedCitizenship: false,
                    otherReasonsOfRenounced: false,
                    notRenounced: false,
                    hasUsPhone: false,
                    hasUsAddress: false,
                    taxResident: false,
                    ssn: false,
                    itin: false,
                }
            }
        },
        methods: {
            validateEngName(update = true) {
                this.errors.engName = this.temp_engName.length > 0 ? !this.temp_engName.match(/^[a-zA-Z_ ]*$/) : true
                if (update !== false) {
                    this.$emit('update', {engName: this.temp_engName})
                }
            },
            validateSsnNumber(update = true) {
                this.errors.ssnNumber = !(this.temp_ssnNumber.length > 0 && this.temp_ssnNumber.replaceAll("-", "").length === 9)
                if (update !== true) {
                    this.$emit('update', {ssnNumber: this.temp_ssnNumber})
                }
            },
            onFileLoad(event, type) {
                this.readyToSubmit = true
                event.preventDefault();
                this.$emit('fileLoad', event.target.files[0], type)
                this.errors[type] = false
            },
            deleteItem(type) {
                this.readyToSubmit = true
                this.$emit('fileDelete', type)
                this.errors[type] = true
            },
            codeIsVerified(code) {
                let form = {}
                this.$emit('verified', {code: code, form: form})
            },
            sendAgain() {
                this.$emit('sendAgain')
            },
            validateForm() {
                this.readyToSubmit = true
                if (this._bornState) {
                    let mode = ["_renouncedCitizenship", "_otherReasonsOfRenounced", "_notRenounced", "_hasUsAddress", "_hasUsPhone", "_taxResident"]
                    if (this._renouncedCitizenship) {
                        mode.push("_renouncedCitizenshipFile")
                    }
                    if (this._otherReasonsOfRenounced) {
                        mode.push("_otherReasonsOfRenouncedFile")
                    }
                    let isError = false;
                    for (let el of mode) {
                        this.errors[el.replace("_", "")] = false;
                        if (this[el] === null || this[el] === undefined) {
                            this.readyToSubmit = false
                            this.errors[el.replace("_", "")] = true
                            isError = true;
                        }
                    }
                    if (this._taxResident) {
                        mode = ["_engName", "_ssn", "_itin", "_ssnNumber"]
                        for (let el of mode) {
                            this.errors[el.replace("_", "")] = false;
                            if (!((el === "_ssn" || el === "_itin") && (this._ssnitin))) {
                                if (this[el] === null || this[el] === undefined) {
                                    this.readyToSubmit = false
                                    isError = true;
                                    this.errors[el.replace("_", "")] = true
                                }
                            }
                        }
                        this.validateEngName(false);
                        this.validateSsnNumber(false);
                    }

                    if (isError === true)
                        this.$toast.error("Все поля обязательны для заполнения")
                }
            },
            submit() {
                this.validateForm()
                if (this.readyToSubmit) this.$emit('onSubmit')
                // this.readyToSubmit = this.timer
            },
        }
    }
</script>

<style scoped lang="scss">
    .form {
        text-align: left;

        & h1 {
            margin-bottom: 32px;
        }

        &__body {
            display: flex;
            flex-direction: column;
            row-gap: 32px;

            & .additional-data {
                width: 100%;
                display: flex;
                row-gap: 24px;
                flex-direction: column;
            }

            & .tax-resident {
                display: flex;
                flex: 1 1 100%;
                align-items: center;
                column-gap: 70px;
                row-gap: 24px;
                flex-wrap: wrap;
                @media screen and (min-width: 1280px) {
                    flex-wrap: nowrap;
                }
                & .col {
                    flex: 1 1 100%;
                    width: 100%;
                    @media screen and (min-width: 1280px) {
                        flex: 1 1 auto;
                        width: auto;
                    }
                }
                & .ssnitin-group {
                    display: flex;
                    flex: 1;
                    column-gap: 24px;
                    row-gap: 24px;
                    flex-wrap: wrap;
                    @media screen and (min-width: 1024px) {
                        flex-wrap: nowrap;
                    }
                    & .group-selecting {
                        width: 100%;
                        @media screen and (min-width: 1024px) {
                            width: auto;
                        }
                    }
                }
            }

            & .block {
                position: relative;
                padding: 0 0 20px;
                 & .load-file {
                     margin-top: 24px;
                 }
                & .row {
                    flex-wrap: nowrap;
                    align-items: flex-end;
                    @media screen and (min-width: 1280px) {
                        flex-wrap: wrap;
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 1px;
                    background-color: $RWM-grey-light;
                    opacity: 0.5;
                }
            }

            & .anketa {
                margin-top: 14px;
            }
        }
    }
</style>
